/* eslint-disable vue/no-unused-vars */
<template>
  <div class="record">
    <div class="_title" v-if="title">酒店订单</div>
    <div class="_content" :class="className">
      <v-card flat class="mt-4">
        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="desserts"
            disable-sort
            disable-filtering
            :items-per-page="desserts.length"
            class="elevation-1"
            no-data-text="暂无数据"
            hide-default-footer
          >
            <!-- eslint-disable-next-line vue/no-unused-vars -->
            <template v-slot:item.productName="{ item }">
              <div v-if="item.standardSingleNum > 0">
                标准间(含单早) {{ item.standardSingleNum }} 间
              </div>
              <div v-if="item.standardDoubleNum > 0">
                标准间(含双早) {{ item.standardDoubleNum }} 间
              </div>
              <div v-if="item.bigbedSingleNum > 0">
                大床房(含单早) {{ item.bigbedSingleNum }} 间
              </div>
              <div v-if="item.bigbedDoubleNum > 0">
                大床房(含双早) {{ item.bigbedDoubleNum }} 间
              </div>
            </template>
            <template v-slot:item.date="{ item }">
              <span>{{item.beginTime|format}}</span>~<span>{{item.endTime|format}}</span>
            </template>
              <template v-slot:item.info="{ item }">
              <span>{{item.contactName}}</span> <span>{{item.contactPhone}}</span>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon small class="mr-2" @click="detailsItem(item.id)">
                详情
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      valid: true,

      headers: [
        {
          text: "预定时间",

          value: "createTime",
        },
        { text: "酒店名称", value: "hotelName" },
        { text: "房间类型", value: "productName" },
        { text: "入住时间", value: "date" },
        { text: "联系人信息", value: "info" },
        { text: "操作", value: "actions", sortable: false },
      ],
      desserts: [],
    };
  },
  created() {
    this.getList();
  },
  props: {
    title: {
      type: Boolean,
      default: true,
    },
    className: {
      type: String,
      default: "",
    },
  },

  methods: {
    getList() {
      this.desserts = [];
      this.$request({
        url: "/hotel/list",
        method: "get",
        data: {},
      }).then((res) => {
        if (res.code == 200) {
          res.data.map((item) => {
            this.desserts.push(item);
          });
        }
      });
    },
    detailsItem(id) {
       this.$router.push({ path: '/hotelDetails', query: { id:id } });
   
    },
    costAction() {},
    
  },
  filters: {
    format(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      // var h = time.getHours();
      // var mm = time.getMinutes();
      // var s = time.getSeconds();
      function add0(m){return m<10?'0'+m:m }
      return (
        y +
        "-" +
        add0(m) +
        "-" +
        add0(d) 
      
      );
    },
  },
};
</script>

<style lang="less">
.record {
  padding: 0 10px;
  padding-bottom: 150px;
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th,
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
  .v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
  .v-data-table > .v-data-table__wrapper > table > thead > tr > td,
  .v-data-footer {
    font-size: 15px !important;
  }
  .v-data-table__mobile-row__header {
    font-size: 15px !important;
  }
  ._content {
    .v-card__text {
      font-size: 15px !important;
      line-height: 25px;
    }
  }
  .ml-100 {
    margin-left: 100px;
  }
  .item-boeder {
    border: 1px solid #c4d5e0;
  }
  .required {
    position: relative;
    width: 80px;
    text-align: end;
    &::before {
      content: "*";
      // position: absolute;
      left: 10%;
      top: 30%;
      font-size: 20px;
      color: red;
    }
  }
  .form-title {
    font-size: 18px;
    background: #efefef;
    color: #3b86aa;
    height: 40px;
    line-height: 40px;
    padding-left: 40px;
    border-radius: 10px;
    font-weight: bold;
  }
  ._title {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
  }
  .desc-title {
    color: #666666;
  }
  .bold {
    font-weight: bold;
  }
  ._red {
    color: #e53333;
  }
}
</style>
